import { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowLeft, faArrowRight, faStar, faStarHalf, faUser } from '@fortawesome/free-solid-svg-icons';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Image from 'next/image';
import { PromotionContext } from '../../pages/_app';
import { useRouter } from 'next/router';

SwiperCore.use([Autoplay, Pagination, Navigation]);

function Opinions({ testimonials, setIsOpen, isASF=false }) {
    let { promo } = useContext(PromotionContext);
    const [testimonial, setTestimonial] = useState([]);
    const router = useRouter()
    const isRegisteredPage = router.pathname === '/asf/registered';
    useEffect(() => {
        const test = JSON.parse(testimonials);
        // const opinions = test.filter((_, i) => i % 2 === 0)
        setTestimonial([...test]);
    }, [testimonials]);

    const closeFormPopup1 = useCallback(() => {
        setIsOpen(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className='h-fit'>
            <section className={`flex ${promo ? 'pt-14' : 'pt-0'} ${isASF? "bg-[#F5F8FC] relative" : "bg-blue-light-bg-new-testimonials"}`} id='!resenas'>
            <div style={isRegisteredPage ? {backgroundImage: "url('https://cdn.fixat.mx/assets/home/bg/no-webp/bg_005.png')"} : {}} className="w-full h-full absolute top-0 bottom-0 bg-cover bg-no-repeat bg-center md:bg-right opacity-15" />
                <div className={`w-full ${isASF ? "bg-gradient-to-b from-[#F5F8FC] to-[#E7EFF7C0] " : "bg-blue-light-bg-new-testimonials"}`}>
                    <div className='w-full max-w-screen-lg mx-auto px-3'>
                        <div className='w-full flex justify-center'>
                            <div className='w-full text-center z-20'>
                                <div className={`${isASF ? 'mt-0' : 'mt-20'}`}>
                                    <h2 className="text-4xl font-fredoka font-bold text-blue-stress">
                                        Ellos ya <span className='block md:inline-block'>duermen sin</span> preocupaciones
                                    </h2>
                                    <div className="xs:w-full md:w-full text-center mx-auto flex xs:flex-row xs:items-center md:flex-row md:justify-center py-4">
                                        <div className="block align-center md:w-1/4">
                                            <span className="block font-fredoka xs:text-4xl md:text-8xl text-blue-new-big-number">
                                                4.8
                                            </span>
                                            <span className="text-fixat-orange xs:text-[8px] md:text-xl">
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStar} />
                                                <FontAwesomeIcon icon={faStarHalf} />
                                                <span className="block font-fredoka text-blue-new-big-number xs:text-[7px] md:text-2xl">
                                                    <FontAwesomeIcon icon={faUser} />
                                                    <span className='pr-1'></span>2,425 total
                                                </span>
                                            </span>
                                        </div>
                                        <div className="flex xs:w-full justify-center md:w-2/3 flex-col xs:mt-3 md:my-8">
                                            <div className="w-full flex justify-center">
                                                <span className="float-left w-6 xs:mt-[-3px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue flex justify-between items-center">
                                                    <FontAwesomeIcon icon={faStar} />
                                                    5
                                                </span>
                                                <span className="float-left relative xs:w-8/12 sm:w-10/12 xs:h-3 md:h-6 xs:mx-1 md:mx-2 bg-gray-bar">
                                                    <span className="w-10/12 bg-fixat-orange xs:h-3 md:h-6 block">
                                                    </span>
                                                </span>
                                                <span className="float-left w-6 xs:mt-[-2px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue">
                                                    1,984
                                                </span>
                                            </div>
                                            <div className="w-full">
                                                <div className="xs:mt-1 md:mt-1.5 flex justify-center">
                                                    <span className="float-left xs:-mt-0.5 md:mt-0 w-6 text-[9px] md:text-base font-fredoka text-dark-blue flex justify-between items-center">
                                                        <FontAwesomeIcon icon={faStar} />
                                                        4
                                                    </span>
                                                    <span className="float-left relative xs:w-8/12 sm:w-10/12 xs:h-3 md:h-6 xs:mx-1 md:mx-2 bg-gray-bar">
                                                        <span className="w-3/12 bg-orange-4-stars xs:h-3 md:h-6 block">
                                                        </span>
                                                    </span>
                                                    <span className="float-left w-6 xs:mt-[-1px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue">
                                                        405
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div className="xs:mt-1 md:mt-1.5 flex justify-center">
                                                    <span className="float-left w-6 xs:mt-[-3px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue flex justify-between items-center">
                                                        <FontAwesomeIcon icon={faStar} />
                                                        3
                                                    </span>
                                                    <span className="float-left relative xs:w-8/12 sm:w-10/12 xs:h-3 md:h-6 xs:mx-1 md:mx-2 bg-gray-bar">
                                                        <span className="w-1/12 bg-green-3-stars xs:h-3 md:h-6 block">
                                                        </span>
                                                    </span>
                                                    <span className="float-left w-6 xs:mt-[-1px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue">
                                                        33
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div className="xs:mt-1 md:mt-1.5 flex justify-center">
                                                    <span className="float-left w-6 xs:mt-[-2px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue flex justify-between items-center">
                                                        <FontAwesomeIcon icon={faStar} />
                                                        <span>2</span>
                                                    </span>
                                                    <span className="float-left relative xs:w-8/12 sm:w-10/12 xs:h-3 md:h-6 xs:mx-1 md:mx-2 bg-gray-bar">
                                                        <span className="w-1.5 bg-green-2-stars xs:h-3 md:h-6 block">
                                                        </span>
                                                    </span>
                                                    <span className="float-left w-6 xs:mt-[-1px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue">
                                                        2
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-full">
                                                <div className="xs:mt-1 md:mt-1.5 flex justify-center">
                                                    <span className="float-left w-6 xs:mt-[-2px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue flex justify-between items-center">
                                                        <FontAwesomeIcon icon={faStar} />
                                                        1
                                                    </span>
                                                    <span className="float-left relative xs:w-8/12 sm:w-10/12 xs:h-3 md:h-6 xs:mx-1 md:mx-2 bg-gray-bar">
                                                        <span className="w-1 bg-gray-1-star xs:h-3 md:h-6 block">
                                                        </span>
                                                    </span>
                                                    <span className="float-left w-6 xs:mt-[-1px] md:mt-0 text-[9px] md:text-base font-fredoka text-dark-blue">
                                                        1
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-full px-4 mt-4 pb-10'>
                            <Swiper
                                breakpoints={{
                                    240: {
                                        slidesPerView: 1,
                                        slidesPerColumn: 1,
                                        slidesPerColumnFill: 'row',
                                        spaceBetween: 10,
                                        slidesPerGroup: 1
                                    },
                                    640: {
                                        slidesPerView: 2,
                                        slidesPerColumn: 1,
                                        slidesPerColumnFill: 'row',
                                        spaceBetween: 20,
                                        slidesPerGroup: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        slidesPerColumn: 2,
                                        slidesPerColumnFill: 'row',
                                        spaceBetween: 25,
                                        allowTouchMove: false,
                                        slidesPerGroup: 3,
                                    }
                                }}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                navigation={{
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }}
                                pagination={{
                                    clickable: true,
                                    swipeHandler: '.swiper-wrapper',
                                    type: 'fraction',
                                }}
                                modules={[Pagination, Autoplay, Navigation]}
                                className="mySwiper-opinions"
                            >
                                {testimonial.map((item, i) => (
                                    <SwiperSlide key={i} className='w-full shadow-lg rounded-2xl'>
                                        {item.type === 'whatsapp' ?
                                            <>
                                                <div className='w-full bg-green-whatsapp-card p-4 flex items-center justify-between rounded-t-2xl'>
                                                    <div className='text-white font-fredoka text-base'>
                                                        {item.name}
                                                    </div>
                                                    <div className='relative w-[26px] h-[26px]'>
                                                        <Image src='https://cdn.fixat.mx/assets/img/no-webp/whatsapp.svg'
                                                            alt="whatsApp logo"
                                                            loading="lazy"
                                                            title="Contáctanos por WhatsApp"
                                                            layout='fill'
                                                            objectFit='contain'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='w-full bg-brown-background-chat px-3 py-[15px] rounded-b-2xl min-h-[170px]'>
                                                    <div className='relative opinions_message_triangle' />
                                                    <div className='w-full bg-white p-2 rounded-md text-left max-h-[142px] overflow-y-auto'>
                                                        <span className='w-full text-[12.8px] font-quicksand font-medium text-black-new-opinions'>
                                                            {item.testimonial}
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                            : item.type === 'facebook' ?
                                                <div>
                                                    <div className='w-full bg-blue-facebook-bg px-4 py-[17px] flex items-center justify-between rounded-t-2xl'>
                                                        <div className='text-white font-fredoka text-base'>
                                                            {item.name}
                                                        </div>
                                                        <div className='text-2xl text-white leading-none'>
                                                            <FontAwesomeIcon icon={faFacebook} />
                                                        </div>
                                                    </div>
                                                    <div className='w-full bg-white px-5 py-5 rounded-b-2xl shadow-lg text-left min-h-[170px]'>
                                                        <span className='w-full text-[12.8px] font-quicksand font-medium text-black-new-opinions block'>
                                                            {item.testimonial}
                                                        </span>
                                                        {item.link !== '' && <a rel='nofollow' href={item.link} target='blank' className='text-[12.8px] text-blue-learn-more-opinions font-quicksand font-medium underline leading-3 gap-2 mt-4 block'>
                                                            <span className=''>Leer más</span>
                                                            <span>
                                                                <FontAwesomeIcon icon={faAngleRight} /><FontAwesomeIcon icon={faAngleRight} />
                                                            </span>
                                                        </a>}
                                                    </div>
                                                </div>
                                                : item.type === 'instagram' ?
                                                    <div>
                                                        <div className='w-full bg-instagram px-4 py-[14px] flex items-center justify-between rounded-t-2xl'>
                                                            <div className='text-white font-fredoka text-base'>
                                                                {item.name}
                                                            </div>
                                                            <div className='text-3xl text-white leading-none'>
                                                                <FontAwesomeIcon icon={faInstagram} />
                                                            </div>
                                                        </div>
                                                        <div className='w-full bg-white px-5 py-5 rounded-b-2xl shadow-lg text-left min-h-[170px]'>
                                                            <span className='w-full text-[12.8px] font-quicksand font-medium text-black-new-opinions block'>
                                                                {item.testimonial}
                                                            </span>
                                                            {item.link !== '' && <a rel='nofollow' href={item.link} target='blank' className='text-[12.8px] text-blue-learn-more-opinions font-quicksand font-medium underline leading-3 gap-2 mt-4 block'>
                                                                <span className=''>Leer más</span>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faAngleRight} /><FontAwesomeIcon icon={faAngleRight} />
                                                                </span>
                                                            </a>}
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <div className='w-full bg-blue-google-bg px-4 py-[14px] flex items-center justify-between rounded-t-2xl'>
                                                            <div className='text-white font-fredoka text-base'>
                                                                {item.name}
                                                            </div>
                                                            <div className='text-3xl text-white leading-none'>
                                                                <div className='relative w-[29px] h-[29px]'>
                                                                    <Image src='https://cdn.fixat.mx/assets/home/img/logo-google-testimoniales.png'
                                                                        alt="Google logo"
                                                                        loading="lazy"
                                                                        layout='fill'
                                                                        objectFit='contain'
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='w-full bg-white px-4 py-4 rounded-b-2xl shadow-lg text-left min-h-[171px] flex gap-4 items-start'>
                                                            <div className='relative min-w-[47px] min-h-[47px]'>
                                                                <Image
                                                                    src={item.image}
                                                                    alt={`Testimonio de ${item.name}`}
                                                                    title={`Testimonio de ${item.name}`}
                                                                    layout='fill'
                                                                    objectFit='cover'
                                                                    loading='lazy'
                                                                />
                                                            </div>
                                                            <div>
                                                                <span className='flex items-center gap-2 text-button-orange text-sm'>
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                    <FontAwesomeIcon icon={faStar} />
                                                                </span>
                                                                <span className='w-full text-[12.8px] font-quicksand font-medium text-black-new-opinions block my-2'>
                                                                    {item.testimonial.length > 152 ? `${item.testimonial.slice(0, 152)} ...` : `${item.testimonial}`}
                                                                </span>
                                                                <span>
                                                                    <a href={item.link} rel='nofollow' target='blank' className='text-[12.8px] text-blue-learn-more-opinions font-quicksand font-medium underline leading-3 flex gap-2 cursor-pointer'>
                                                                        <span className=''>Leer más</span>
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faAngleRight} /><FontAwesomeIcon icon={faAngleRight} />
                                                                        </span>
                                                                    </a>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </>
                                        }
                                    </SwiperSlide>
                                ))
                                }
                                <div className="swiper-button-next"><FontAwesomeIcon icon={faArrowRight} /></div>
                                <div className="swiper-button-prev"><FontAwesomeIcon icon={faArrowLeft} /></div>
                            </Swiper>
                        </div>
                        <div className="w-full relative">
                            <div className="flex justify-center pb-12">
                                {isASF ? (
                                    <button className="font-fredoka inline-block text-white relative rounded-full text-xl h-auto cursor-pointer px-1 hover:bg-[#23BE5C] bg-[#27D367] w-10/12 md:w-5/12 py-3 tracking-wider" onClick={() => router.push("/asf/payment")} >
                                        ¡Quiero pagar mi Análisis!
                                    </button>
                                ): (
                                    <button onClick={closeFormPopup1} className="w-full sm:w-3/5 lg:w-2/5 rounded-5xl p-3.5 bg-button-orange md:hover:bg-hover-orange font-fredoka font-normal text-blue-new-button-text text-2xl text-center">Me interesa</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Opinions